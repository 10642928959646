import {IProject} from "../../api/models/IProject";

export const projects: IProject[] = [
    {
        title: 'My own portfolio',
        image: '/images/website.jpg',
        live: 'https://acanoen.fr'
    },
    {
        title: 'My old portfolio',
        image: '/images/old_website.jpg',
        code: 'https://github.com/acanoenfr/website-1th'
    }
];
