import {LogoPicture} from "../Pictures/Logo";
import {INavLink} from "../../api/models/INavLink";

export const navLinks: INavLink[] = [
    {
        label: 'About',
        href: '/about',
        mobile: true
    },
    {
        label: 'Portfolio',
        href: '/portfolio',
        mobile: true
    },
    {
        label: <LogoPicture />,
        href: '/',
        mobile: false
    },
    {
        label: 'Blog',
        href: '/blog',
        mobile: true
    }
];
