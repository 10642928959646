import { SunIcon, MoonIcon } from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";

export function ThemeToggle() {
    const [theme, setTheme] = useState(
        localStorage.getItem("theme")
            ? localStorage.getItem("theme")
            : "light"
    );

    const handleToggle = (evt: any) => {
        const localTheme = evt.target.checked
            ? "dark"
            : "light";
        setTheme(localTheme);
        localStorage.setItem("theme", localTheme);
    };

    useEffect(() => {
        document.querySelector("html")?.setAttribute("data-theme", theme || 'light');
    }, [theme]);

    return (
        <button type="button" className="btn btn-square btn-ghost">
            <label className="swap swap-rotate w-12 h-12">
                <input type="checkbox" onChange={handleToggle} checked={theme === 'dark'} />
                <SunIcon className="w-8 h-8 swap-on" />
                <MoonIcon className="w-8 h-8 swap-off" />
            </label>
        </button>
    );
}
