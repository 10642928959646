import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedin} from "@fortawesome/free-brands-svg-icons"
import {ISocialIcon} from "../../api/models/ISocialIcon";

export const socialIcons: ISocialIcon[] = [
    {
        icon: <FontAwesomeIcon icon={faGithub} />,
        href: 'https://github.com/acanoenfr'
    },
    {
        icon: <FontAwesomeIcon icon={faLinkedin} />,
        href: 'https://linkedin.com/in/acanoenfr'
    }
];
