import {Link} from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

export function NotFound() {
    return (
        <div className="h-full flex flex-col justify-center items-center">
            <h1 className="text-6xl font-bold my-4">404</h1>
            <p className="text-2xl my-4">Page non trouvée</p>
            <Link to="/" className="link link-primary inline-flex justify-between items-center text-xl no-underline">
                <ChevronLeftIcon className="w-5 h-5 mr-1" />
                Retour à l'accueil
            </Link>
        </div>
    );
}
