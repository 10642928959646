import {projects} from "./constants";
import {getTextGradient} from "../../utils/gradients";
import {Link} from "react-router-dom";

export function Portfolio() {
    return (
        <div className="text-center">
            <div className="flex flex-wrap items-center mb-10">
                {projects.map((project, index) => (
                    <div key={index} className="flex flex-col items-center justify-center md:w-1/2">
                        <img src={project.image} alt={project.title} className="rounded-xl w-4/5 p-0.5 border-4 border-neutral hover:border-primary" />
                        <h1 className={`${getTextGradient()} text-2xl font-bold my-2`}>{project.title}</h1>
                        <div className="flex gap-2 mb-4">
                            {project.live && <Link to={project.live} target="_blank" className="link link-primary text-xl no-underline">Live</Link>}
                            {project.code && <Link to={project.code} target="_blank" className="link link-accent text-xl no-underline">Code</Link>}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
