import {useEffect} from "react";
import {Bars3Icon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import {navLinks} from "./constants";

export function MobileHeader() {
    useEffect(() => {
        let details = document.querySelector('details');
        const handleClick = ({target}: MouseEvent) => {
            if (!details?.contains(target as Node)) {
                details?.removeAttribute('open');
            }
        }

        document.addEventListener('click', (e) => handleClick(e))

        return () => {
            document.removeEventListener('click', (e) => handleClick(e));
        }
    }, []);

    return (
        <details className="dropdown dropdown-start md:hidden">
            <summary className="btn btn-square btn-ghost">
                <Bars3Icon className="w-8 h-8" />
            </summary>
            <ul tabIndex={0} className="dropdown-content menu z-[1] bg-base-200 p-6 rounded-box shadow w-56 gap-2">
                {navLinks.filter(x => x.mobile).map((link, index) => (
                    <li key={index}><Link to={link.href}>{link.label}</Link></li>
                ))}
            </ul>
        </details>
    );
}
