import {getTextGradient} from "../../utils/gradients";
import {AcademicCapIcon, BriefcaseIcon, LifebuoyIcon} from "@heroicons/react/24/solid";

export function About() {
    return (
        <div className="mx-4">
            <h1 className="text-3xl font-bold">About me</h1>
            <p className="my-4">
                My name is <span className={`${getTextGradient()} font-bold`}>Alexandre</span> and I am a <span className={`${getTextGradient()} font-bold`}>Full-Stack Developer</span>, specializing in these domains:
                <ul className="m-4 space-y-2">
                    <li>⚙️ For backend development: Java Spring, PHP Symfony and .NET Framework</li>
                    <li>📱 For frontend development: React, Angular and Vue</li>
                    <li>💽 For database: MySQL/MariaDB, PostgreSQL, NoSQL with MongoDB</li>
                    <li>🏢 For project management: SCRUM, Agile Development, DevOps</li>
                </ul>
                All above elements, are not exhaustive because I am capable to learn quickly new skills because I am someone who are serious, motivated and very passionated for programming web applications and website.
            </p>
            <p className="my-4">
                Outside of coding, my favourites activities are:
                <ul className="m-4 space-y-2">
                    <li>📚 Reading, studying and learning new things</li>
                    <li>🎥 Watching movies and TV series</li>
                    <li>🛬 Playing simulations video games, such as Microsoft Flight Simulator</li>
                </ul>
            </p>
            <h2 className="text-2xl font-bold">
                My career as <span className={getTextGradient()}>Full-Stack Developer</span>
            </h2>
            <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical my-4">
                <li>
                    <div className="mx-2 timeline-middle">
                        <BriefcaseIcon className="w-5 h-5" />
                    </div>
                    <div className="mx-2 timeline-start mb-10 md:text-end">
                        <time className="font-mono italic">February 2022 - May 2024</time>
                        <div className="text-lg font-black">Full-Stack Developer (Akkodis)</div>
                        Mission within the Lill'iad service center: my role was to develop customer applications and keep them in working order.
                    </div>
                    <hr />
                </li>
                <li>
                    <div className="mx-2 timeline-middle">
                        <LifebuoyIcon className="w-5 h-5" />
                    </div>
                    <div className="mx-2 timeline-end mb-10 md:text-end">
                        <time className="font-mono italic">March 2021 - February 2022</time>
                        <div className="text-lg font-black">Web Developer (MineDisney)</div>
                        Design and development of a user interface listing association events with React. Design, with the SaaS Figma, of an application which allows centralizing the authentication of players on all web applications.
                    </div>
                    <hr />
                </li>
                <li>
                    <div className="mx-2 timeline-middle">
                        <BriefcaseIcon className="w-5 h-5" />
                    </div>
                    <div className="mx-2 timeline-start mb-10 md:text-end">
                        <time className="font-mono italic">December 2019 - March 2020</time>
                        <div className="text-lg font-black">.NET Developer (Sysdeco)</div>
                        Development and maintenance of IT solutions in .NET on projects such as an internal tool for listing customers or a generic tool for managing employee time clocks.
                    </div>
                    <hr />
                </li>
                <li>
                    <div className="mx-2 timeline-middle">
                        <BriefcaseIcon className="w-5 h-5" />
                    </div>
                    <div className="mx-2 timeline-end mb-10 md:text-end">
                        <time className="font-mono italic">April 2019 - July 2019</time>
                        <div className="text-lg font-black">Web developer (ETD Solutions)</div>
                        Design and development of an ERP composed of several microservices in Node.js for technical resources and documents templates management
                    </div>
                    <hr />
                </li>
                <li>
                    <div className="mx-2 timeline-middle">
                        <BriefcaseIcon className="w-5 h-5" />
                    </div>
                    <div className="mx-2 timeline-start mb-10 md:text-end">
                        <time className="font-mono italic">May 2017 - June 2017</time>
                        <div className="text-lg font-black">Web developer (Grand-Fort-Philippe Town Hall)</div>
                        Development of an appointment management application with address book for a medical office
                    </div>
                    <hr />
                </li>
            </ul>
            <h2 className="text-2xl font-bold">
                My Diplomas and Certifications as <span className={getTextGradient()}>Full-Stack Developer</span>
            </h2>
            <ul className="my-4 mx-2 space-y-2">
                <li>🏢 2022: Angular Front-End Development</li>
                <li>🏫 2019: Professional License, Mobile and Internet Development</li>
                <li>🏫 2018: Higher Technician Certificate, Digital Systems, Electronic & Communications option</li>
                <li>🏫 2016: High School Diploma, Science and Technology of Industry and Sustainable Development, Information and Digital Systems option</li>
            </ul>
        </div>
    );
}
