import {ThemeToggle} from "../Theme/ThemeToggle";
import {navLinks} from "./constants";
import {Link} from "react-router-dom";
import {MobileHeader} from "./MobileHeader";

export function Header() {
    return (
        <header className="navbar sticky top-0 bg-base-200 md:bg-base-100 z-50">
            <div className="w-full flex md:justify-center justify-between md:items-center md:gap-2">
                <div className="menu menu-horizontal items-center gap-10 hidden md:flex">
                    {navLinks.map((link, index) => (
                        <Link
                            key={index}
                            className="btn btn-ghost uppercase h-full"
                            to={link.href}
                        >
                            {link.label}
                        </Link>
                    ))}
                    <ThemeToggle />
                </div>
                <MobileHeader />
                <Link
                    className="btn btn-square btn-ghost uppercase md:hidden"
                    to={navLinks[2].href}
                >
                    {navLinks[2].label}
                </Link>
                <div className="md:hidden">
                    <ThemeToggle />
                </div>
            </div>
        </header>
    );
}
