import React from "react";
import {BrowserRouter} from "react-router-dom";
import routes from "./routes/routes";
import {Footer} from "./components/Footer/Footer";
import {Header} from "./components/Header/Header";

export function App() {
    return (
        <div className="max-w-screen-lg min-h-screen mx-auto flex flex-col justify-center items-center">
            <BrowserRouter>
                <Header />
                <main className="flex-grow flex flex-col justify-center items-center">
                    {routes}
                </main>
                <Footer />
            </BrowserRouter>
        </div>
    );
}
