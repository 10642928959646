import React from "react";
import {Link} from "react-router-dom";

import {socialIcons} from "./constants";
import {ProfilePicture} from "../../components/Pictures/Profile";
import {getTextGradient} from "../../utils/gradients";

export function Home() {
    return (
        <div className="flex flex-col md:flex-row justify-center items-center gap-10 md:gap-20">
            <ProfilePicture />
            <div className="text-center md:text-left my-4">
                <h1 className="text-3xl">
                    Hey, I'm <span className={`${getTextGradient()} font-bold`}>Alexandre</span> !
                    <span className="mx-1 animate-wave inline-block">🤚🏼</span>
                </h1>
                <h2 className="text-xl my-3">I'm a <span className={`${getTextGradient()} font-bold`}>Full-Stack Developer</span>.</h2>
                <ul className="mx-2 space-y-2 text-md text-center md:text-left">
                    <li>🗺️ I'm currently living in Saint-Omer (France)</li>
                    <li>🔭 I’m currently working on my personal projects</li>
                    <li>💬 Ask me about everything about programming</li>
                    <li>
                        📫 How to reach me:
                        <Link className="mx-2 link link-primary no-underline" to="mailto:contact@acanoen.fr">contact[at]acanoen.fr</Link>
                    </li>
                </ul>
                <ul className="flex justify-center text-3xl gap-10 mt-4">
                    {socialIcons.map((social, index) => (
                        <li key={index} className="hover:scale-125 text-base-100 dark:text-neutral transition-all ease-in-out">
                            <Link to={social.href} target="_blank">{social.icon}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
