import {Route, Routes} from "react-router-dom";
import {NotFound} from "../pages/errors/NotFound";
import {Home} from "../pages/home/Home";
import {BlogPage} from "../pages/blog/BlogPage";
import {About} from "../pages/about/About";
import {Portfolio} from "../pages/portfolio/Portfolio";

const routes = (
    <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<BlogPage />} />

        <Route path="*" element={<NotFound />} />
    </Routes>
);

export default routes;
